import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'Восточное шоссе 41',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: null,
		selectButton: {
			options: [
				{
					value: '//kinogigant.com',
					title: 'Гигант',
				},
				{
					value: '//hollywood.kinogigant.com',
					title: 'Голливуд',
				},
				{
					value: '//oscar.kinogigant.com',
					title: 'Оскар',
				},
			],
			selectedOptionValue: '//oscar.kinogigant.com',
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
